import { memo } from 'react';
import styled from 'styled-components';

import CompanyFeaturesButtonList from '~/components/CompanyFeaturesButtonList';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SelectCompanySubsidiaryDiv } from '~/components/settings/shared';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  max-width: 840px;

  ${theme.medias.extraSmall} {
    width: 100%;
    max-width: 100%;
    grid-template-columns: minmax(0, 1fr);
  }
`;

const TabDashboardFeatures = memo(() => {
  const { currentCompany } = useCompany();
  const { companyFeatures } = useCompanyFeatures();

  return (
    <>
      <SelectCompanySubsidiaryDiv>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
          <SettingsSelectCompany />
        </div>
      </SelectCompanySubsidiaryDiv>
      <GridDiv>
        {!currentCompany?.id ? (
          <TabSectionHeader title={i18n.t('companyFeatures.missingCompany')} />
        ) : (
          <>
            <div>
              <TabSectionHeader title={i18n.t('companyFeatures.general')} />
              <CompanyFeaturesButtonList
                companyFeaturesToShow={{
                  endOfDayReset: companyFeatures.endOfDayReset,
                  agentDebugPanel: companyFeatures.agentDebugPanel,
                  biometricData: companyFeatures.biometricData,
                  dataAnalysisReports: companyFeatures.dataAnalysisReports,
                  contactsOnCall: companyFeatures.contactsOnCall,
                }}
              />
              <TabSectionHeader title={i18n.t('companyFeatures.pages')} />
              <CompanyFeaturesButtonList
                companyFeaturesToShow={{
                  logsPage: companyFeatures.logsPage,
                  logsPageOnlySuperAdmins: companyFeatures.logsPageOnlySuperAdmins,
                  integrationsPage: companyFeatures.integrationsPage,
                }}
              />
              <TabSectionHeader title={i18n.t('companyFeatures.video')} />
              <CompanyFeaturesButtonList
                companyFeaturesToShow={{
                  remoteVideoStreamingControl: companyFeatures.remoteVideoStreamingControl,
                  playbackRecordedVideos: companyFeatures.playbackRecordedVideos,
                }}
              />
            </div>
            <div>
              <TabSectionHeader title={i18n.t('companyFeatures.sensors')} />
              <CompanyFeaturesButtonList
                companyFeaturesToShow={{
                  emergencyButton: companyFeatures.emergencyButton,
                  heartRateSensor: companyFeatures.heartRateSensor,
                  physiologicalTemperatureSensor: companyFeatures.physiologicalTemperatureSensor,
                  gasSensor: companyFeatures.gasSensor,
                  radiationSensor: companyFeatures.radiationSensor,
                  oxygenSupplySensor: companyFeatures.oxygenSupplySensor,
                  impactDetectionFront: companyFeatures.impactDetectionFront,
                  impactDetectionBack: companyFeatures.impactDetectionBack,
                }}
              />
              <TabSectionHeader title={i18n.t('common.location')} />
              <CompanyFeaturesButtonList
                companyFeaturesToShow={{
                  locationHistory: companyFeatures.locationHistory,
                  locationHistoryOnlySuperAdmins: companyFeatures.locationHistoryOnlySuperAdmins,
                  realtimeLocation: companyFeatures.realtimeLocation,
                  showLocationAccuracyCircle: companyFeatures.showLocationAccuracyCircle,
                }}
              />
            </div>
          </>
        )}
      </GridDiv>
    </>
  );
});

TabDashboardFeatures.displayName = 'TabDashboardFeatures';

export default TabDashboardFeatures;
