import { Alert } from 'antd';
import { memo, useMemo } from 'react';

import { KNOWN_ALARM_TYPES } from '~/config/defaults';
import i18n from '~/locales/i18n';
import type { AlarmConfiguration } from '~/types/configurationAlarm';

interface Props {
  allAlarmPlatformConfigurations: AlarmConfiguration[];
}

const TopAlarmConfigurationAlert = memo(({ allAlarmPlatformConfigurations }: Props) => {
  const alarmNamesNotInConfigurations = useMemo(
    () =>
      KNOWN_ALARM_TYPES.filter(
        (alarmName) =>
          !allAlarmPlatformConfigurations.some(
            (alarmConfiguration) => alarmConfiguration.name === alarmName,
          ),
      ),
    [allAlarmPlatformConfigurations],
  );

  const missingAlarmTypes = useMemo(
    () =>
      alarmNamesNotInConfigurations
        .map((alarmName) => i18n.t(`general.alarm.type.${alarmName}`))
        .join(', '),
    [alarmNamesNotInConfigurations],
  );

  if (alarmNamesNotInConfigurations.length === 0) {
    return null;
  }

  return (
    <Alert
      style={{ marginTop: '1rem' }}
      type="warning"
      message={
        <>
          <b>⚠️ {i18n.t('alarmConfigurations.missingAlarmPlatformConfigurations')}: </b>{' '}
          {alarmNamesNotInConfigurations.length}
          {missingAlarmTypes ? ` | ${missingAlarmTypes}` : ''}
        </>
      }
    />
  );
});

TopAlarmConfigurationAlert.displayName = 'TopAlarmConfigurationAlert';

export default TopAlarmConfigurationAlert;
