import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import type { Alarm, GasAlarmValue, ALARM_LEVEL } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import parseJSON from '~/utils/parse/parseJSON';

const Div = styled.div<{ $alarmLevel: ALARM_LEVEL }>`
  background: ${(props) => getAlarmColorFromLevel(props.$alarmLevel).background};
  color: ${(props) => getAlarmColorFromLevel(props.$alarmLevel).text};
  padding: 4px 8px;
  border-radius: 8px;
  display: inline-block;
`;

interface Props {
  alarm: Alarm;
  className?: string;
  style?: CSSProperties;
}

const AlarmTypePill = memo(({ alarm, className, style }: Props) => {
  const gasName = (parseJSON(alarm.value) as GasAlarmValue)?.gas;

  return (
    <Div $alarmLevel={alarm.level} className={className} style={style}>
      {i18n.t(`general.alarm.type.${alarm.type}`)}
      {gasName ? ` (${gasName?.toUpperCase()})` : ''}
    </Div>
  );
});

AlarmTypePill.displayName = 'AlarmTypePill';

export default AlarmTypePill;
