import { Space } from 'antd';
import { memo } from 'react';
import { FiMapPin } from 'react-icons/fi';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { GpsCoordinates } from '~/types/sensor';

import LabelWithValue from './LabelWithValue';

interface Props {
  address: string | undefined;
  gps: GpsCoordinates | null | undefined;
}

const InfoLocation = memo(({ address, gps }: Props) => {
  const gpsLocation = gps?.lat && gps?.lng ? `${gps?.lat}, ${gps?.lng}` : undefined;

  const addressToRender = address || gpsLocation;

  return (
    <Space align="center">
      <FiMapPin color={theme.colors.darkBlue} size={22} />
      <LabelWithValue
        data-id="DetailsPopup-InfoLocation"
        label={i18n.t('common.location')}
        value={addressToRender || i18n.t<string>('carrierDetailsPopup.locationNotFound')}
        hasCopyToClipboard={!!addressToRender}
      />
    </Space>
  );
});

InfoLocation.displayName = 'InfoLocation';

export default InfoLocation;
