import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import styled from 'styled-components';

import useMutationDeleteAgent from '~/apollo/hooks/agent/useMutationDeleteAgent';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SelectCompanySubsidiaryDiv,
  SettingsButtonsFlexDiv,
  SettingsTopFlexDiv,
} from '~/components/settings/shared';
import TimeAgo from '~/components/TimeAgo';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, AgentAlertLevel } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import formatPhoneNumber from '~/utils/phoneNumber/formatPhoneNumber';
import defaultTablePaginationProps from '~/utils/table/defaultTablePaginationProps';

interface TableDataType {
  key: Key;
  completeName: string;
  wearinApp: 'YES' | 'NO';
  brainId: string;
  email: string;
  acronym: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  team: string;
  createdAt: string;
  actions: ReactNode;
  // For the profile status circle
  status: AGENT_STATUS;
  alertLevel: AgentAlertLevel;
  isOffline: boolean;
}

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const CIRCLE_WIDTH = '32px';

const ProfileCircleDiv = styled.div`
  align-items: center;
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  font-size: 11px;
  height: ${CIRCLE_WIDTH};
  width: ${CIRCLE_WIDTH};
  margin: 0;
  cursor: help;
`;

interface Props {
  isBackofficePage: boolean;
}

const TabAgents = memo(({ isBackofficePage }: Props) => {
  const { isLoading: isLoadingCurrentUser, isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const { agents, isLoading: isAgentsLoading, refetchAgentsContext } = useAgentsContext();

  const { deleteAgent, loading: isDeleteAgentLoading } = useMutationDeleteAgent();

  const isSuperOrCompanyAdmin = isSuperAdmin || isCompanyAdmin;

  const isLoading = isLoadingCurrentUser || isAgentsLoading || isDeleteAgentLoading;

  const totalRefetch = useCallback(async () => {
    await refetchAgentsContext();
  }, [refetchAgentsContext]);

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'completeName',
        title: i18n.t('common.name'),
        sorter: (a, b) => a.completeName.toLowerCase().localeCompare(b.completeName.toLowerCase()),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps({
          dataIndex: 'completeName',
          title: i18n.t('common.name'),
          renderWithHighlight: ({ text, record, highlightedNode }) => {
            const agentStatusColor = getAgentStatusColor(record);
            const statusTranslation = i18n.t(
              `general.agentStatus.${record?.isOffline && record?.status !== AGENT_STATUS.alert ? 'offline' : record?.status}`,
            );
            return text ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div>
                  <Tooltip
                    placement="left"
                    title={`${statusTranslation}${record?.alertLevel ? ` (${i18n.t(`general.alarm.level.${record?.alertLevel?.toLowerCase()}`)})` : ''}`}
                  >
                    <ProfileCircleDiv
                      style={{
                        backgroundColor: agentStatusColor,
                        borderColor: agentStatusColor,
                        color: theme.colors.white,
                      }}
                      data-id="details-popup-bullet-label"
                    >
                      {record?.acronym}
                    </ProfileCircleDiv>
                  </Tooltip>
                </div>
                <span>
                  <b>{highlightedNode}</b>
                </span>
              </div>
            ) : (
              '-'
            );
          },
        }),
      },
      {
        dataIndex: 'email',
        title: i18n.t('common.email'),
        sorter: (a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'email',
          title: i18n.t('common.email'),
          renderWithHighlight: ({ text, highlightedNode }) => (text ? highlightedNode : '-'),
        }),
      },
      {
        dataIndex: 'brainId',
        title: i18n.t('common.wearinBrain'),
        sorter: (a, b) => a.brainId.toLowerCase().localeCompare(b.brainId.toLowerCase()),
        render: (text) => (
          <Tooltip
            title={
              text ? (
                <div>
                  <b>{i18n.t('common.wearinBrain')} ID:</b>
                  <br />
                  {text}
                </div>
              ) : undefined
            }
            placement="top"
          >
            <Tag style={{ cursor: text ? 'help' : 'default' }} color={text ? 'default' : 'error'}>
              {text ? i18n.t('common.yes') : i18n.t('common.no')}
            </Tag>
          </Tooltip>
        ),
      },
      {
        dataIndex: 'wearinApp',
        title: i18n.t('common.wearinApp'),
        sorter: (a, b) => a.wearinApp.toLowerCase().localeCompare(b.wearinApp.toLowerCase()),
        render: (text) => (
          <Tag color={text === 'YES' ? 'blue' : 'default'}>
            {text === 'YES' ? i18n.t('common.yes') : i18n.t('common.no')}
          </Tag>
        ),
      },
      {
        dataIndex: 'team',
        title: i18n.t('common.team'),
        sorter: (a, b) => a.team.toLowerCase().localeCompare(b.team.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'team',
          title: i18n.t('common.team'),
          renderWithHighlight: ({ text, highlightedNode }) => (text ? highlightedNode : '-'),
        }),
      },
      {
        dataIndex: 'phoneNumber',
        title: i18n.t('common.phoneNumber'),
        sorter: (a, b) => a.phoneNumber.toLowerCase().localeCompare(b.phoneNumber.toLowerCase()),
        render: (text) => formatPhoneNumber(text) || text,
      },
      {
        dataIndex: 'createdAt',
        title: i18n.t('common.created'),
        sorter: (a, b) => a.createdAt.toLowerCase().localeCompare(b.createdAt.toLowerCase()),
        render: (text) => (
          <Tooltip title={formatDateTime(text)}>
            <span style={{ cursor: 'help' }}>
              <TimeAgo date={text} />
            </span>
          </Tooltip>
        ),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [getColumnSearchProps],
  );

  const data: TableDataType[] = useMemo(
    () =>
      agents.map((agent) => ({
        completeName: agent.completeName,
        key: agent.id,
        wearinApp: agent.__typename === 'Carrier_Cognito' ? 'YES' : 'NO',
        brainId: agent.deviceName || '',
        email: agent.email || '-',
        acronym: agent.attributes.acronym?.trim() || '-',
        firstName: agent.attributes.first_name?.trim() || '-',
        lastName: agent.attributes.last_name?.trim() || '-',
        phoneNumber: agent.attributes.phone_number || '-',
        team: agent.attributes.team || '-',
        createdAt: agent.createdAt,
        status: agent.status,
        alertLevel: agent.alertLevel,
        isOffline: agent.isOffline,
        actions: (
          <ActionsDiv>
            <Tooltip title={i18n.t('companySettingsPage.agentsTab.editAgent')}>
              <Button
                onClick={() => {
                  openModal({
                    type: 'agentAddEdit',
                    agentId: agent.id,
                    refetchOnCompleted: totalRefetch,
                  });
                }}
                icon={<EditOutlined />}
                data-id="edit-agent-btn"
                data-agent-id={agent.id}
                data-agent-email={agent.email}
              />
            </Tooltip>
            <Tooltip title={i18n.t('companySettingsPage.agentsTab.deleteAgent')}>
              <Button
                data-id={`delete-agent-btn-${agent.email || agent.id}`}
                icon={<DeleteOutlined />}
                danger
                disabled={isLoading}
                onClick={() => {
                  openModal({
                    type: 'confirmation',
                    title: i18n.t('companySettingsPage.agentsTab.deleteAgent'),
                    description: (
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: i18n.t<string>(
                            'companySettingsPage.agentsTab.areYouSureYouWantToDeleteAgentHtml',
                            {
                              email: agent.email || agent.completeName,
                            },
                          ),
                        }}
                      />
                    ),
                    actionType: 'danger',
                    action: async () => {
                      try {
                        await deleteAgent({
                          variables: {
                            agentId: agent.id,
                          },
                        });
                        await totalRefetch();
                        notification.success({
                          message: i18n.t('common.success'),
                          description: i18n.t('companySettingsPage.agentsTab.agentDeletedSuccess'),
                        });
                      } catch (error) {
                        notification.error({
                          message: (error as any)?.message || i18n.t('common.error'),
                        });
                      }
                    },
                  });
                }}
              />
            </Tooltip>
          </ActionsDiv>
        ),
      })),
    [agents, isLoading, openModal, totalRefetch, deleteAgent],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv>
        {isBackofficePage && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
            <SettingsSelectCompany isLoading={isLoading} />
          </div>
        )}
        {isSuperOrCompanyAdmin && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedSubsidiary')} />
            <SettingsSelectSubsidiary isLoading={isLoading} />
          </div>
        )}
      </SelectCompanySubsidiaryDiv>
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('companySettingsPage.agentsTab.subsidiaryAgents')} (${agents.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              openModal({
                type: 'agentAddEdit',
                agentId: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
            data-id={isLoading ? undefined : 'add-agent-btn'}
          >
            {i18n.t('companySettingsPage.agentsTab.createAgent')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={defaultTablePaginationProps}
      />
    </WrapperDiv>
  );
});

TabAgents.displayName = 'TabAgents';

export default TabAgents;
