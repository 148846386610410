import { Popover, PopoverProps } from 'antd';
import { memo, useMemo, type ReactNode } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getActualBarsFromLteSignal from '~/utils/lteSignal/getActualBarsFromLteSignal';
import getYesOrNo from '~/utils/parse/getYesOrNo';

import PopupSectionList, { type ListItem } from './components/PopupSectionList';
import PopupSectionTitle from './components/PopupSectionTitle';

const ContentDiv = styled.div`
  cursor: help;
`;

interface Props {
  agent: Agent | undefined;
  children: ReactNode;
  showBrain: boolean;
  showLteSignal: boolean;
  showBattery: boolean;
  placement: PopoverProps['placement'];
}

const BrainBatteryLteSignalPopover = memo(
  ({ agent, children, showBrain, showLteSignal, showBattery, placement }: Props) => {
    const brainItems: ListItem[] = useMemo(
      () =>
        [
          {
            title: i18n.t<string>('brainBatteryLteSignal.id'),
            description: agent?.deviceName,
          },
        ].filter((item) => !!item.description),
      [agent?.deviceName],
    );

    const lteSignalItems: ListItem[] = useMemo(
      () =>
        [
          {
            title: i18n.t<string>('brainBatteryLteSignal.bars'),
            description:
              typeof agent?.sensors?.lteSignalStrength?.value?.bars === 'number'
                ? `${getActualBarsFromLteSignal(agent.sensors.lteSignalStrength.value.bars)}/4`
                : undefined,
          },
          /*
          {
            title: i18n.t<string>('brainBatteryLteSignal.networkConnectivity'),
            description: getLteSignalStrengthTranslation(
              agent?.sensors?.lteSignalStrength?.value?.rsrp,
            ),
            descriptionTooltip: Number.isFinite(agent?.sensors?.lteSignalStrength?.value?.rsrp)
              ? `${agent?.sensors?.lteSignalStrength?.value?.rsrp} dBm`
              : undefined,
          },
          {
            title: i18n.t<string>('brainBatteryLteSignal.dataTransmission'),
            description: getLteSignalQualityTranslation(
              agent?.sensors?.lteSignalStrength?.value?.rsrq,
            ),
            descriptionTooltip: Number.isFinite(agent?.sensors?.lteSignalStrength?.value?.rsrq)
              ? `${agent?.sensors?.lteSignalStrength?.value?.rsrq} dB`
              : undefined,
          },
          */
        ].filter((item) => !!item.description),
      [agent?.sensors?.lteSignalStrength?.value?.bars],
    );

    const batteryItems: ListItem[] = useMemo(
      () =>
        [
          {
            title: i18n.t<string>('brainBatteryLteSignal.level'),
            description: agent?.sensors?.battery?.value?.battery_level
              ? `${agent.sensors.battery.value.battery_level}%`
              : undefined,
            descriptionTooltip:
              agent?.sensors?.battery?.value?.battery_level &&
              agent?.sensors?.battery?.value?.battery_health
                ? `${agent.sensors.battery.value.battery_health}% battery health`
                : undefined,
          },
          /*
          {
            title: i18n.t<string>('brainBatteryLteSignal.voltage'),
            description: agent?.sensors?.battery?.value?.voltage
              ? `${agent.sensors.battery.value.voltage} V`
              : undefined,
          },
          */
          {
            title: i18n.t<string>('brainBatteryLteSignal.charging'),
            description:
              typeof agent?.sensors?.battery?.value?.is_charging === 'number'
                ? getYesOrNo(agent.sensors.battery.value.is_charging === 1)
                : undefined,
          },
        ].filter((item) => !!item.description),
      [
        agent?.sensors?.battery?.value?.battery_level,
        agent?.sensors?.battery?.value?.battery_health,
        agent?.sensors?.battery?.value?.is_charging,
      ],
    );

    const showAnything = showBrain || showLteSignal || showBattery;

    if (!agent || !showAnything) {
      return null;
    }

    return (
      <Popover
        content={
          <div>
            {showBrain && (
              <>
                <PopupSectionTitle
                  style={{ marginTop: 0 }}
                  title={i18n.t('brainBatteryLteSignal.brainInformation')}
                  status={undefined}
                  showConnected
                  agent={agent}
                />
                <PopupSectionList items={brainItems} />
              </>
            )}
            {showLteSignal && lteSignalItems.length > 0 && (
              <>
                <PopupSectionTitle
                  style={showBrain ? undefined : { marginTop: 0 }}
                  title={i18n.t('brainBatteryLteSignal.cellularConnectivity')}
                  status={agent?.equipmentStatus?.lteSignalStrength?.status}
                  showConnected={false}
                  agent={agent}
                />
                <PopupSectionList items={lteSignalItems} />
              </>
            )}
            {showBattery && batteryItems.length > 0 && (
              <>
                <PopupSectionTitle
                  title={i18n.t('brainBatteryLteSignal.battery')}
                  status={agent?.equipmentStatus?.battery?.status}
                  showConnected={false}
                  agent={agent}
                />
                <PopupSectionList items={batteryItems} />
              </>
            )}
          </div>
        }
        placement={placement}
      >
        <ContentDiv>{children}</ContentDiv>
      </Popover>
    );
  },
);

BrainBatteryLteSignalPopover.displayName = 'BrainBatteryLteSignalPopover';

export default BrainBatteryLteSignalPopover;
