import i18n from '~/locales/i18n';
import { AGENT_STATUS, type Agent } from '~/types/agent';

export default function getAgentStatusTranslation(
  agent: Partial<Pick<Agent, 'isOffline' | 'status'>>,
): string {
  return agent?.isOffline && agent?.status !== AGENT_STATUS.alert
    ? i18n.t(`general.agentStatus.offline`)
    : i18n.t(`general.agentStatus.${agent?.status}`);
}
