import { createContext, useContext, useMemo, type ReactNode } from 'react';

import useAgentsContext from '~/context/useAgentsContext';
import type { Agent } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';

import { DETAILS_POPUP_TYPE } from '../config/types';

const initialState: {
  type: DETAILS_POPUP_TYPE;
  agent?: Agent;
  vehicle?: Vehicle;
} = {
  type: DETAILS_POPUP_TYPE.agent,
  agent: undefined,
  vehicle: undefined,
};

const DetailsPopupContext = createContext(initialState);

export function DetailsPopupContextProvider({
  queryAgentId,
  children,
}: {
  queryAgentId: string;
  children: ReactNode;
}) {
  const { getAgent, getVehicle } = useAgentsContext();

  const agent = useMemo(() => getAgent(queryAgentId), [getAgent, queryAgentId]);

  const maybePlateNumber = agent?.attributes?.plate_number;

  const vehicle = useMemo(
    () => (maybePlateNumber ? getVehicle(maybePlateNumber) : undefined),
    [getVehicle, maybePlateNumber],
  );

  const value = useMemo(
    () => ({
      type: maybePlateNumber ? DETAILS_POPUP_TYPE.vehicle : DETAILS_POPUP_TYPE.agent,
      agent,
      vehicle,
    }),
    [maybePlateNumber, agent, vehicle],
  );

  return <DetailsPopupContext.Provider value={value}>{children}</DetailsPopupContext.Provider>;
}

export default function useDetailsPopupContext() {
  return useContext(DetailsPopupContext);
}
