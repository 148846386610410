import { Checkbox, Switch } from 'antd';
import snakeCase from 'lodash/snakeCase';
import { memo } from 'react';
import styled from 'styled-components';

import useMutationSetCompanyAttribute from '~/apollo/hooks/company/useMutationSetCompanyAttribute';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useCompanyEmergencyContacts from '~/hooks/useCompanyEmergencyContacts';
import useCompanyFeatures, { type CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

const Ul = styled.ul`
  margin: 0 0 22px;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface Props {
  companyFeaturesToShow: Partial<CompanyFeatures>;
}

const CompanyFeaturesButtonList = memo(({ companyFeaturesToShow }: Props) => {
  const { setCompanyAttribute, isLoading: isMutationSetCompanyAttributeLoading } =
    useMutationSetCompanyAttribute();
  const { companyFeatures } = useCompanyFeatures();
  const { currentCompany } = useCompany();
  const { isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const { setOnCallPhoneNumbers, isLoading: isEmergencyContactsLoading } =
    useCompanyEmergencyContacts();

  const isLoading = isMutationSetCompanyAttributeLoading || isEmergencyContactsLoading;

  const isSuperAdminOrCompanyAdmin = isSuperAdmin || isCompanyAdmin;

  type FeatureEntries = [keyof typeof companyFeatures, boolean][];

  const featuresEntries = Object.entries(companyFeaturesToShow) as FeatureEntries;

  return (
    <Ul>
      {featuresEntries.map(([featureKey, isEnabled]) => {
        const isOnlySuperAdmins = featureKey.endsWith('OnlySuperAdmins');

        const maybeParentFeatureKey = isOnlySuperAdmins
          ? featureKey.replace('OnlySuperAdmins', '')
          : null;
        const isParentKeyActive = companyFeatures[maybeParentFeatureKey as keyof CompanyFeatures];

        const Component = isOnlySuperAdmins ? Checkbox : Switch;

        return (
          <Li key={featureKey} data-id={`company-feature-${featureKey}`}>
            <Component
              checked={isEnabled}
              disabled={!isSuperAdminOrCompanyAdmin}
              loading={Component === Switch ? isLoading : undefined}
              onChange={() => {
                const toCheck = !isEnabled;
                openModal({
                  type: 'confirmation',
                  title: null,
                  description: (
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: i18n.t<string>('companyFeatures.modalDescriptionHtml', {
                          feature: i18n.t(`companyFeatures.${featureKey}`),
                          company: currentCompany?.name,
                          status: toCheck
                            ? i18n.t<string>('companyFeatures.toggle.on')
                            : i18n.t<string>('companyFeatures.toggle.off'),
                        }),
                      }}
                    />
                  ),
                  actionType: toCheck ? 'primary' : 'danger',
                  action: async () => {
                    // If a user unchecks the Contacts on-call feature flag, we need to clear the on-call phone number list
                    if (featureKey === 'contactsOnCall' && !toCheck) {
                      await setOnCallPhoneNumbers([]);
                    }
                    await setCompanyAttribute({
                      name: `feature_${snakeCase(featureKey)}`,
                      value: toCheck ? 'enabled' : 'disabled',
                    });
                  },
                });
              }}
            />
            <span style={{ opacity: isOnlySuperAdmins && !isParentKeyActive ? 0.25 : 1 }}>
              {isOnlySuperAdmins
                ? i18n.t('companyFeatures.onlyVisibleToSuperAdmins')
                : i18n.t(`companyFeatures.${featureKey}`)}
            </span>
          </Li>
        );
      })}
    </Ul>
  );
});

CompanyFeaturesButtonList.displayName = 'CompanyFeaturesButtonList';

export default CompanyFeaturesButtonList;
