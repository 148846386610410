import { memo, useCallback, useMemo } from 'react';

import SectionList, {
  type SectionListItem,
} from '~/components/DetailsPopup/components/SectionList';
import Warning from '~/components/Warning';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

function calculateDataQuality(value: number | undefined): 'Unknown' | 'Bad' | 'Low' | 'Good' {
  if (typeof value === 'undefined') {
    return 'Unknown';
  }
  if (value < 1) {
    return 'Bad';
  }
  if (value === 1) {
    return 'Low';
  }
  return 'Good';
}

interface Props {
  agent: Agent | undefined;
}

const ContentPhysiologicalTemperature = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  const bodyTemperature = agent?.equipmentStatus?.physiologicalTemperature?.status
    ? agent?.sensors?.physiologicalTemperature?.value?.body_temp
    : undefined;

  const skinTemperature = agent?.equipmentStatus?.physiologicalTemperature?.status
    ? agent?.sensors?.physiologicalTemperature?.value?.skin_temp
    : undefined;

  const dataQualityValue = agent?.equipmentStatus?.physiologicalTemperature?.status
    ? agent?.sensors?.physiologicalTemperature?.value?.data_qual
    : undefined;

  const dataQuality = useMemo(() => calculateDataQuality(dataQualityValue), [dataQualityValue]);

  const isWorking: boolean = useMemo(
    () =>
      getEquipmentStatusDysfunctionsColor(agent?.equipmentStatus?.physiologicalTemperature?.status)
        ?.isWorking,
    [agent?.equipmentStatus?.physiologicalTemperature?.status],
  );

  const isDataQualityLow = dataQuality === 'Bad' || dataQuality === 'Low';

  const getCommonParams = useCallback(
    (value: number | null | undefined): Partial<SectionListItem> => {
      const formattedValue = formatNumberWithUnit({
        number: isWorking ? value : undefined,
        unit: '\u00B0C',
      });

      return {
        style: {
          opacity: !isWorking || isDataQualityLow ? 0.4 : 1,
        },
        tooltip:
          isWorking && isDataQualityLow
            ? i18n.t(`carrierDetailsPopup.physiologicalSensors.dataQualityTooltip${dataQuality}`)
            : undefined,
        valueText:
          isWorking && isDataQualityLow ? <Warning>{formattedValue}</Warning> : formattedValue,
      };
    },
    [dataQuality, isDataQualityLow, isWorking],
  );

  const list: SectionListItem[] = useMemo(
    () => [
      ...(companyFeatures.physiologicalTemperatureSensor
        ? [
            {
              ...getCommonParams(bodyTemperature),
              labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.bodyTemperature'),
              'data-id': 'bodyTemperaturePhysiologicalSensor',
            },
          ]
        : []),
      ...(companyFeatures.physiologicalTemperatureSensor
        ? [
            {
              ...getCommonParams(skinTemperature),
              labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.skinTemperature'),
              'data-id': 'skinTemperaturePhysiologicalSensor',
            },
          ]
        : []),
    ],
    [
      companyFeatures.physiologicalTemperatureSensor,
      getCommonParams,
      bodyTemperature,
      skinTemperature,
    ],
  );

  if (!agent) {
    return null;
  }

  return <SectionList data-id="SectionPhysiologicalSensors" elements={list} />;
});

ContentPhysiologicalTemperature.displayName = 'ContentPhysiologicalTemperature';

export default ContentPhysiologicalTemperature;
