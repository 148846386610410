import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { EquipmentStatusDetails, EquipmentStatusKeys } from '~/types/equipment';
import getDysfunctionalEquipment from '~/utils/equipment/getDysfunctionalEquipment';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';

enum EQUIPMENT_STATUS {
  ok = 'ok',
  unavailable = 'unavailable',
  dysfunction = 'dysfunction',
  offline = 'offline',
  connectionLost = 'connectionLost',
}

interface Status {
  label: string;
  color: {
    popover: string;
    table: string;
  };
}

function getStatus(equipmentStatus: EQUIPMENT_STATUS, count?: number): Status {
  return {
    label: i18n.t(`carrierDetailsPopup.equipment.status.${equipmentStatus}`, { count }),
    color: {
      [EQUIPMENT_STATUS.ok]: {
        popover: theme.colors.brightGreen,
        table: theme.colors.brightGreen,
      },
      [EQUIPMENT_STATUS.unavailable]: {
        popover: theme.colors.midGrey,
        table: theme.colors.midGrey,
      },
      [EQUIPMENT_STATUS.dysfunction]: {
        popover: theme.colors.yellow,
        table: theme.colors.yellow,
      },
      [EQUIPMENT_STATUS.connectionLost]: {
        popover: theme.colors.midGrey,
        table: theme.colors.midGrey,
      },
      [EQUIPMENT_STATUS.offline]: {
        popover: theme.colors.midGrey,
        table: theme.colors.black,
      },
    }[equipmentStatus],
  };
}

function getDysfunctionStatus({
  agent,
  companyFeatures,
}: {
  agent: Agent;
  companyFeatures: CompanyFeatures;
}): Status {
  const count = getDysfunctionalEquipment({ agent, companyFeatures }).length;
  return count ? getStatus(EQUIPMENT_STATUS.dysfunction, count) : getStatus(EQUIPMENT_STATUS.ok);
}

function getSensorStatus(statusDetails?: EquipmentStatusDetails): Status {
  const sensorState = getEquipmentStatusDysfunctionsColor(statusDetails?.status).state;

  if (sensorState === 'dysfunction') {
    return getStatus(EQUIPMENT_STATUS.dysfunction);
  }

  if (sensorState === 'regular') {
    return getStatus(EQUIPMENT_STATUS.unavailable);
  }

  return getStatus(EQUIPMENT_STATUS.ok);
}

type StatusesReturnType = Record<
  'status' | 'brain' | Exclude<EquipmentStatusKeys, 'connectionLost' | 'offline'>,
  Status
>;

export default function computeEquipmentStatuses({
  agent,
  isOffline,
  isConnectionLost,
  companyFeatures,
}: {
  agent: Agent;
  isOffline: boolean;
  isConnectionLost: boolean;
  companyFeatures: CompanyFeatures;
}): StatusesReturnType {
  const isOfflineOrConnectionLost = isOffline || isConnectionLost;

  const offlineOrConnectionStatus = isOffline
    ? EQUIPMENT_STATUS.offline
    : EQUIPMENT_STATUS.connectionLost;

  const result: StatusesReturnType = {
    status: isOfflineOrConnectionLost
      ? getStatus(offlineOrConnectionStatus)
      : getDysfunctionStatus({ agent, companyFeatures }),
    brain: isOfflineOrConnectionLost
      ? getStatus(offlineOrConnectionStatus)
      : getStatus(EQUIPMENT_STATUS.ok),
    emergencyButton: getSensorStatus(agent.equipmentStatus.emergencyButton),
    heartRate: getSensorStatus(agent.equipmentStatus.heartRate),
    physiologicalTemperature: getSensorStatus(agent.equipmentStatus.physiologicalTemperature),
    gas: getSensorStatus(agent.equipmentStatus.gas),
    radiation: getSensorStatus(agent.equipmentStatus.radiation),
    oxygenSupply: getSensorStatus(agent.equipmentStatus.oxygenSupply),
    traakFront: getSensorStatus(agent.equipmentStatus.traakFront),
    traakBack: getSensorStatus(agent.equipmentStatus.traakBack),
    battery: getSensorStatus(agent.equipmentStatus.battery),
    lteSignalStrength: getSensorStatus(agent.equipmentStatus.lteSignalStrength),
  };

  return result;
}
