import type { CarrierItem } from '~/apollo/hooks/agent/queries/QuerySubsidiaryCarrierList';
import { SENSOR_NAME_VARIABLE } from '~/types/sensor';

export default function isAgentNew(device: CarrierItem['device']): boolean {
  if (device) {
    const sensorVariableNames: SENSOR_NAME_VARIABLE[] = [
      SENSOR_NAME_VARIABLE.system,
      SENSOR_NAME_VARIABLE.battery,
      SENSOR_NAME_VARIABLE.lteSignalStrength,
      SENSOR_NAME_VARIABLE.connected,
      SENSOR_NAME_VARIABLE.gps,
      SENSOR_NAME_VARIABLE.bodyMultiSensorV1,
      SENSOR_NAME_VARIABLE.heartRate,
      SENSOR_NAME_VARIABLE.gas,
      SENSOR_NAME_VARIABLE.radiation,
      SENSOR_NAME_VARIABLE.connected_history,
      SENSOR_NAME_VARIABLE.brainStop,
    ];

    const hasSomeSensorValue = sensorVariableNames.some(
      (sensorVariable) => device[sensorVariable]?.items?.[0]?.value != null,
    );

    return !hasSomeSensorValue;
  }

  return true;
}
