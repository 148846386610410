import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Button, Empty, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import SettingsSelectCompany, {
  StyledSelect,
} from '~/components/settings/components/SettingsSelectCompany';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SettingsTopFlexDiv,
  SettingsButtonsFlexDiv,
  SelectCompanySubsidiaryDiv,
} from '~/components/settings/shared';
import { KNOWN_ALARM_TYPES } from '~/config/defaults';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_TYPE } from '~/types/alarm';

import AlarmConfigurationsTable from './components/AlarmConfigurationsTable';
import BottomAlarmConfigurationList from './components/BottomAlarmConfigurationList';
import TopAlarmConfigurationAlert from './components/BottomAlarmConfigurationList/TopAlarmConfigurationAlert';
import useAlarmConfigurations from './hooks/useAlarmConfigurations';

const ALARM_TYPE_OPTIONS_WITH_ALL = KNOWN_ALARM_TYPES.map((alarmType) => ({
  label: i18n.t(`general.alarm.type.${alarmType}`),
  value: alarmType,
  title: i18n.t(`general.alarm.description.${alarmType}`),
}));

const abnormalActivityAlarmTypes: DefaultOptionType = {
  label: i18n.t('alarmConfigurations.abnormalActivity'),
  options: ALARM_TYPE_OPTIONS_WITH_ALL.filter((alarmType) =>
    [ALARM_TYPE.abnormal_stops, ALARM_TYPE.long_stops, ALARM_TYPE.stress].includes(alarmType.value),
  ),
};

const gasLevelsAlarmTypes: DefaultOptionType = {
  label: i18n.t('alarmConfigurations.gasLevels'),
  options: ALARM_TYPE_OPTIONS_WITH_ALL.filter((alarmType) =>
    [ALARM_TYPE.gas_high, ALARM_TYPE.gas_danger].includes(alarmType.value),
  ),
};

const impactDetectionAlarmTypes: DefaultOptionType = {
  label: i18n.t('alarmConfigurations.impactDetectionAlarms'),
  options: ALARM_TYPE_OPTIONS_WITH_ALL.filter((alarmType) =>
    [ALARM_TYPE.traak_front, ALARM_TYPE.traak_back].includes(alarmType.value),
  ),
};

const ALARM_TYPE_OPTIONS: SelectProps['options'] = [
  {
    label: i18n.t('alarmConfigurations.general'),
    options: ALARM_TYPE_OPTIONS_WITH_ALL.filter(
      (alarmType) =>
        ![
          ...abnormalActivityAlarmTypes.options.map(
            (option: { value: ALARM_TYPE }) => option.value,
          ),
          ...gasLevelsAlarmTypes.options.map((option: { value: ALARM_TYPE }) => option.value),
          ...impactDetectionAlarmTypes.options.map((option: { value: ALARM_TYPE }) => option.value),
        ].includes(alarmType.value),
    ),
  },
  abnormalActivityAlarmTypes,
  gasLevelsAlarmTypes,
  impactDetectionAlarmTypes,
];

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const TabAlarmConfigurations = memo(() => {
  const { isSubsidiaryAdmin } = useCurrentUserContext();
  const { currentCompany } = useCompany();
  const { currentSubsidiary } = useSubsidiary();

  const [selectedAlarmType, setSelectedAlarmType] = useState<ALARM_TYPE>(
    ALARM_TYPE_OPTIONS[0].options[0].value,
  );

  const {
    allAlarmPlatformConfigurations,
    alarmConfigurations,
    isLoading: isAlarmConfigurationsContextLoading,
    refetchAlarmConfigurations,
  } = useAlarmConfigurations({ alarmType: selectedAlarmType });

  useEffect(() => {
    (async () => {
      if (alarmConfigurations.length > 0) {
        await refetchAlarmConfigurations();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { openModal } = useModalsContext();

  const { isLoading: isLoadingCurrentUser } = useCurrentUserContext();

  const isLoading = isLoadingCurrentUser || isAlarmConfigurationsContextLoading;

  const hasCompanyAndSubsidiary = !!currentCompany?.id && !!currentSubsidiary?.id;

  if (isSubsidiaryAdmin) {
    return null;
  }

  return (
    <WrapperDiv>
      {!isLoading && (
        <TopAlarmConfigurationAlert
          allAlarmPlatformConfigurations={allAlarmPlatformConfigurations}
        />
      )}
      <SelectCompanySubsidiaryDiv>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
          <SettingsSelectCompany isLoading={isLoading} />
        </div>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.selectedSubsidiary')} />
          <SettingsSelectSubsidiary isLoading={isLoading} />
        </div>
        <div>
          <TabSectionHeader title={i18n.t('common.alarmType')} />
          <StyledSelect
            disabled={isLoading}
            value={selectedAlarmType}
            onChange={(value) => {
              setSelectedAlarmType(value as ALARM_TYPE);
            }}
            placeholder={i18n.t('footer.selectCompany')}
            options={ALARM_TYPE_OPTIONS}
            loading={isLoading}
          />
        </div>
      </SelectCompanySubsidiaryDiv>
      {hasCompanyAndSubsidiary ? (
        <>
          <SettingsTopFlexDiv>
            <TabSectionHeader
              title={
                isLoading
                  ? i18n.t('common.loading')
                  : `${i18n.t('alarmConfigurations.alarmConfigurationsForAlarmType', {
                      alarmType: i18n.t(`general.alarm.type.${selectedAlarmType}`),
                    })} (${alarmConfigurations.length})`
              }
            />
            <SettingsButtonsFlexDiv>
              <Button
                loading={isLoading}
                icon={<ReloadOutlined />}
                onClick={async () => {
                  await refetchAlarmConfigurations();
                }}
              >
                {i18n.t('common.refresh')}
              </Button>
              <Button
                type="primary"
                loading={isLoading}
                icon={<PlusOutlined />}
                onClick={async () => {
                  openModal({
                    type: 'alarmConfigurationAddEdit',
                    isEdit: false,
                    itemScope: undefined,
                    itemId: undefined,
                    selectedAlarmType,
                    alarmConfigurations,
                    refetchAlarmConfigurations,
                  });
                }}
              >
                {i18n.t('configurationsGeneric.createConfiguration')} (
                {i18n.t(`general.alarm.type.${selectedAlarmType}`)})
              </Button>
            </SettingsButtonsFlexDiv>
          </SettingsTopFlexDiv>
          <AlarmConfigurationsTable
            selectedAlarmType={selectedAlarmType}
            alarmConfigurations={alarmConfigurations}
            isLoading={isLoading}
            refetchAlarmConfigurations={refetchAlarmConfigurations}
          />
          {!isLoading && (
            <BottomAlarmConfigurationList
              allAlarmPlatformConfigurations={allAlarmPlatformConfigurations}
            />
          )}
        </>
      ) : (
        <Empty
          style={{
            background: 'white',
            margin: '16px 0 0',
            padding: '3rem 16px',
            borderRadius: '10px',
          }}
          description={i18n.t('configurationsGeneric.pleaseSelectACompanyAndASubsidiary')}
        />
      )}
    </WrapperDiv>
  );
});

TabAlarmConfigurations.displayName = 'TabAlarmConfigurations';

export default TabAlarmConfigurations;
