import { Button, Empty, Input, Segmented, Spin } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';

import AgentButton from './components/AgentButton';

const Aside = styled.aside`
  width: 100%;
  height: 100%;
  z-index: ${theme.layers.base};
  overflow: auto;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  ${theme.medias.lteSmall} {
    display: none;
  }
`;

const TopDiv = styled.div`
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const ScrollDiv = styled.div`
  height: calc(100dvh - ${theme.dimensions.navbarHeight}px - 97px);
  overflow-y: auto;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const EmtpyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 64px;
  margin-bottom: 32px;
`;

const LoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 32px;
`;

type UpOrDown = 'up' | 'down';

interface Props {
  idFromQuery: string | undefined;
  vehicleFromQuery: string | undefined;
}

const ControlSidebar = memo(({ idFromQuery, vehicleFromQuery }: Props) => {
  const [searchText, setSearchText] = useState<string>('');
  const [sortBy, setSortBy] = useState<keyof Agent>('status');
  const [sortOrder, setSortOrder] = useState<UpOrDown>('down');

  const { agents, isInitialLoading, isLoading: isAgentsContextLoading } = useAgentsContext();

  const isLoading = isInitialLoading || isAgentsContextLoading;

  const onlineAgents = useMemo(
    () => agents.filter((agent) => !agent?.isOffline || agent?.status === AGENT_STATUS.alert),
    [agents],
  );

  const agentsToShow = useMemo(() => {
    const filteredAgents = onlineAgents.filter((agent) => {
      const propertiesToSearch = [
        agent?.completeName,
        agent?.attributes?.acronym,
        agent?.attributes?.plate_number,
        agent?.team,
      ].filter(Boolean) as string[];

      return propertiesToSearch.some((property) =>
        property.toLowerCase().trim().includes(searchText.toLowerCase().trim()),
      );
    });

    if (sortBy === 'status') {
      const sortedByStatus = sortAgentsByStatusAndName(filteredAgents);
      return sortOrder === 'down' ? sortedByStatus : sortedByStatus.reverse();
    }

    return orderBy(filteredAgents, [sortBy], [sortOrder === 'down' ? 'asc' : 'desc']);
  }, [onlineAgents, sortOrder, searchText, sortBy]);

  return (
    <Aside>
      <TopDiv>
        <Input
          style={{ height: '40px' }}
          placeholder={i18n.t<string>('controlSidebar.searchAgents')}
          onChange={(event) => setSearchText(event.target.value)}
          value={searchText}
          allowClear
          suffix={
            searchText ? null : (
              <span style={{ color: '#c6c6c6' }}>
                {isLoading || onlineAgents.length === 0 ? '' : onlineAgents.length.toString()}
              </span>
            )
          }
        />
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '8px',
          }}
        >
          <Segmented<string>
            options={[
              { label: i18n.t('common.status'), value: 'status' satisfies keyof Agent },
              { label: i18n.t('common.name'), value: 'completeName' satisfies keyof Agent },
            ]}
            onChange={(value) => {
              setSortBy(value as keyof Agent);
            }}
          />
          <Segmented<string>
            options={[
              { label: '↓', value: 'down' satisfies UpOrDown },
              { label: '↑', value: 'up' satisfies UpOrDown },
            ]}
            onChange={(value) => {
              setSortOrder(value as UpOrDown);
            }}
          />
        </div>
      </TopDiv>
      <ScrollDiv>
        {isLoading && (
          <LoadingDiv>
            <Spin />
          </LoadingDiv>
        )}
        {!isLoading && agentsToShow.length > 0 && (
          <Ul>
            {agentsToShow.map((agent) => (
              <li key={agent?.id}>
                <AgentButton
                  searchText={searchText}
                  agent={agent}
                  idFromQuery={idFromQuery}
                  vehicleFromQuery={vehicleFromQuery}
                />
              </li>
            ))}
          </Ul>
        )}
        {!isLoading && agentsToShow.length === 0 && (
          <EmtpyDiv>
            <Empty
              description={
                searchText
                  ? i18n.t('controlSidebar.noAgentsFound')
                  : i18n.t('controlSidebar.noAgentsOnline')
              }
            />
            {searchText && (
              <Button
                type="default"
                onClick={() => {
                  setSearchText('');
                }}
              >
                {i18n.t('controlSidebar.resetFilter')}
              </Button>
            )}
          </EmtpyDiv>
        )}
      </ScrollDiv>
    </Aside>
  );
});

ControlSidebar.displayName = 'ControlSidebar';

export default ControlSidebar;
