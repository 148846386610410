import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import type { ApolloError } from '@apollo/client';
import { Tooltip, Button, Radio, Space, Alert } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import styled from 'styled-components';

import useMutationAssociateAlarmAdmin from '~/apollo/hooks/alarm/useMutationAssociateAlarmAdmin';
import useMutationDisassociateAlarmAdmin from '~/apollo/hooks/alarm/useMutationDisassociateAlarmAdmin';
import useMutationDismissAlarm from '~/apollo/hooks/alarm/useMutationDismissAlarm';
import useMutationRequestDismissAlarm from '~/apollo/hooks/alarm/useMutationRequestDismissAlarm';
import AlarmTypePill from '~/components/AlarmTypePill';
import TimeAgo from '~/components/TimeAgo';
import Warning from '~/components/Warning';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import { Agent } from '~/types/agent';
import { ALARM_DISMISS_TYPE, ALARM_SOURCE, AlarmWithCarrier } from '~/types/alarm';
import computeAlarmStartingTime from '~/utils/alarm/computeAlarmStartingTime';
import { formatDateTime } from '~/utils/dateTime';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import getUserName from '~/utils/user/getUserName';

const ManageAlertModalSpace = styled(Space)`
  display: grid;
  gap: 12px;
`;

const FooterDiv = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledWarning = styled(Warning)`
  margin-top: 8px;
`;

const AssociateButton = styled(Button)`
  margin-right: auto;
`;

function getErrorMessage(error: ApolloError | undefined): ReactNode {
  return (
    <>
      <b>{i18n.t('carrierDetailsPopup.alarmControlModal.failTitle')}</b>
      {error?.message && (
        <>
          <br />
          <b>{i18n.t('common.error')}:</b> {error?.message}
        </>
      )}
    </>
  );
}

const AlarmManageTab = memo(
  ({
    agent,
    alarm,
    onClose,
  }: {
    agent: Agent | undefined;
    alarm: AlarmWithCarrier | undefined;
    onClose: () => void;
  }) => {
    const { currentUser, isSuperAdmin } = useCurrentUserContext();

    const {
      dismissAlarm,
      isLoading: isDismissLoading,
      error: dismissError,
    } = useMutationDismissAlarm();
    const {
      requestDismissAlarm,
      isLoading: isRequestDismissLoading,
      error: requestDismissError,
    } = useMutationRequestDismissAlarm();
    const {
      associateAlarmAdmin,
      loading: isAssociateLoading,
      error: associateAlarmError,
    } = useMutationAssociateAlarmAdmin();
    const {
      disassociateAlarmAdmin,
      loading: isDisassociateLoading,
      error: disassociateAlarmError,
    } = useMutationDisassociateAlarmAdmin();

    const isLoading =
      isDismissLoading || isRequestDismissLoading || isAssociateLoading || isDisassociateLoading;

    const [selectedDismissType, setSelectedDismissType] = useState<ALARM_DISMISS_TYPE>();

    const isDisassociate = alarm?.administrator?.id === currentUser?.id;
    const associateOrDisassociateKey = isDisassociate ? 'disassociate' : 'associate';

    // Dismiss error
    useEffect(() => {
      if (dismissError) {
        notification.error({
          message: getErrorMessage(dismissError),
        });
      }
    }, [dismissError]);

    // Request dismiss error
    useEffect(() => {
      if (requestDismissError) {
        notification.error({
          message: getErrorMessage(requestDismissError),
        });
      }
    }, [requestDismissError]);

    // Associate error
    useEffect(() => {
      if (associateAlarmError) {
        notification.error({
          message: getErrorMessage(associateAlarmError),
        });
      }
    }, [associateAlarmError]);

    // Disassociate error
    useEffect(() => {
      if (disassociateAlarmError) {
        notification.error({
          message: getErrorMessage(disassociateAlarmError),
        });
      }
    }, [disassociateAlarmError]);

    const handleDismiss = useCallback(async () => {
      const isRequest = alarm?.source === ALARM_SOURCE.Device;
      const action = isRequest ? requestDismissAlarm : dismissAlarm;
      try {
        if (selectedDismissType) {
          await action({
            variables: {
              alarmId: alarm?.id || '',
              reason: selectedDismissType,
            },
          });
          onClose();
          notification.success({
            message: i18n.t(
              `carrierDetailsPopup.alarmControlModal.${selectedDismissType?.toLowerCase()}SuccessTitle`,
            ),
          });
        }
      } catch (error) {
        logger.error('AlarmManageTab: handleDismiss: error', { error });
      }
    }, [alarm?.id, alarm?.source, dismissAlarm, onClose, requestDismissAlarm, selectedDismissType]);

    const handleAssociateOrDisassociate = useCallback(async () => {
      const action = isDisassociate ? disassociateAlarmAdmin : associateAlarmAdmin;
      try {
        await action({
          variables: {
            alarmId: alarm?.id || '',
            adminId: currentUser?.id || '',
          },
        });
        onClose();
        notification.success({
          message: i18n.t(
            `carrierDetailsPopup.alarmControlModal.${associateOrDisassociateKey}SuccessTitle`,
          ),
        });
      } catch (error) {
        logger.error('AlarmManageTab: handleAssociateOrDisassociate: error', { error });
      }
    }, [
      alarm?.id,
      associateAlarmAdmin,
      associateOrDisassociateKey,
      currentUser?.id,
      disassociateAlarmAdmin,
      isDisassociate,
      onClose,
    ]);

    const dismissRadioTooltip = useMemo(() => {
      if (!alarm?.can_dismiss_dashboard) {
        return i18n.t('carrierDetailsPopup.alarmControlModal.tooltipCannotDismiss');
      }
      return undefined;
    }, [alarm?.can_dismiss_dashboard]);

    const cancelRadioTooltip = useMemo(() => {
      if (!alarm?.can_cancel) {
        return i18n.t('carrierDetailsPopup.alarmControlModal.tooltipCannotCancel');
      }
      return undefined;
    }, [alarm?.can_cancel]);

    const associateButtonTooltip = useMemo(() => {
      if (!alarm?.can_assign) {
        return i18n.t('carrierDetailsPopup.alarmControlModal.tooltipCannotAssign');
      }
      if (isSuperAdmin) {
        return i18n.t('carrierDetailsPopup.alarmControlModal.tooltipCannotAssignSuperAdmins');
      }
      return undefined;
    }, [alarm?.can_assign, isSuperAdmin]);

    if (!alarm || !!alarm?.dismissed_at) {
      return null;
    }

    const { alertTime, alertTimeFormatted } = computeAlarmStartingTime(alarm);

    return (
      <>
        <ManageAlertModalSpace data-id="manage-alert-modal">
          {alertTimeFormatted && alertTime && (
            <div>
              {alertTimeFormatted}{' '}
              <Tooltip title={formatDateTime(alarm?.created_at)} placement="right">
                <span style={{ cursor: 'help' }}>
                  (<TimeAgo date={alarm?.created_at} />)
                </span>
              </Tooltip>
            </div>
          )}
          <div data-id="manage-alert-modal-alert-info">
            <div
              style={{
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              {agent?.completeName}
            </div>
            <AlarmTypePill alarm={alarm} />
            <div style={{ opacity: 0.5, marginTop: '0.5rem' }}>
              {i18n.t(`general.alarm.description.${alarm.type}`)}
            </div>
          </div>
          {!alarm?.can_cancel && !alarm?.can_dismiss_dashboard && (
            <Alert
              description={i18n.t('carrierDetailsPopup.alarmControlModal.cannotCancelWarning')}
              type="warning"
              showIcon
              closable={false}
            />
          )}
          <div>
            <Radio.Group
              value={selectedDismissType}
              onChange={({ target }) => setSelectedDismissType(target.value)}
            >
              <Space direction="vertical">
                <Tooltip placement="left" title={dismissRadioTooltip}>
                  <Radio
                    value={ALARM_DISMISS_TYPE.Acknowledged}
                    disabled={!alarm.can_dismiss_dashboard}
                    data-id="acknowledge-alarm-option"
                  >
                    {i18n.t('carrierDetailsPopup.alarmControlModal.options.acknowledged')}
                  </Radio>
                </Tooltip>
                <Tooltip placement="left" title={cancelRadioTooltip}>
                  <Radio
                    value={ALARM_DISMISS_TYPE.Cancelled}
                    disabled={!alarm.can_cancel}
                    data-id="cancel-alarm-option"
                  >
                    {i18n.t('carrierDetailsPopup.alarmControlModal.options.cancelled')}
                  </Radio>
                </Tooltip>
              </Space>
            </Radio.Group>
            {agent?.isConnectionLost && (
              <StyledWarning>
                {i18n.t(`carrierDetailsPopup.alarmControlModal.connectionLostWarning`)}
              </StyledWarning>
            )}
          </div>
          {alarm?.administrator && (
            <div>
              <InfoCircleOutlined /> {i18n.t('general.generalAlarms.alarmAssigned')}{' '}
              <b>{getUserName(alarm?.administrator)}</b>
            </div>
          )}
        </ManageAlertModalSpace>
        <FooterDiv>
          <Tooltip title={associateButtonTooltip} placement="bottom">
            <AssociateButton
              disabled={
                !alarm.can_assign ||
                isSuperAdmin ||
                (!!alarm.administrator && alarm.administrator.id !== currentUser?.id)
              }
              danger
              loading={isLoading || isAssociateLoading || isDisassociateLoading}
              onClick={handleAssociateOrDisassociate}
              data-id={`${associateOrDisassociateKey}-alarm-btn`}
            >
              {i18n.t(`carrierDetailsPopup.alarmControlModal.${associateOrDisassociateKey}`)}
            </AssociateButton>
          </Tooltip>
          <Button
            type="default"
            disabled={!selectedDismissType || (!alarm.can_dismiss_dashboard && !alarm?.can_cancel)}
            loading={isLoading || isDismissLoading || isRequestDismissLoading}
            onClick={handleDismiss}
            data-id="manage-alert-modal-submit-btn"
          >
            {i18n.t('carrierDetailsPopup.alarmControlModal.submitButtonLabel')}
          </Button>
        </FooterDiv>
      </>
    );
  },
);

AlarmManageTab.displayName = 'AlarmManageTab';

export default AlarmManageTab;
