import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import DotChartOutlined from '@ant-design/icons/DotChartOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import FullscreenExitOutlined from '@ant-design/icons/FullscreenExitOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { ConfigProvider, Button, Checkbox, Select, Skeleton, Switch, Tooltip } from 'antd';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import { TbUserQuestion } from 'react-icons/tb';
import styled from 'styled-components';

import AlarmNotificationCard from '~/components/AlarmNotificationCard';
import AlarmNotificationGroup from '~/components/AlarmNotificationGroup';
import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_LEVEL, ALARM_TYPE, type AlarmWithCarrier } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import { formatDate } from '~/utils/dateTime';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(360px, auto);
  gap: 1rem;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
    padding-bottom: 16px;
  }
`;

const AlarmLevelFiltersUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  overflow-x: auto;
`;

const AlarmLevelFilterButton = styled.button`
  margin: 0;
  padding: 8px 12px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.thinGrey};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;

  &:disabled {
    cursor: progress;
  }

  &:hover {
    &:not:disabled {
      border-color: #c1c1c1;
    }
  }

  & > span {
    color: ${theme.colors.white};
    padding: 4px 8px;
    border-radius: 4px;
  }
`;

const Aside = styled.aside``;

const StickySideFiltersDiv = styled.div`
  padding: 16px;
  background: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.thinGrey};
  position: sticky;
  top: calc(${theme.dimensions.navbarHeight}px);
  bottom: 0;
  height: calc(100dvh - ${theme.dimensions.navbarHeight}px);

  ${theme.medias.lteSmall} {
    height: auto;
    position: initial;
    top: 0;
    margin-bottom: ${theme.dimensions.alarmsBottomBannerHeight}px;
  }
`;

const AgentSelect = styled(Select<string>)`
  width: 100%;
  max-width: 100%;
`;

const FilterH4 = styled.h4`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FiltersUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 16px;

  & > li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
  }
`;

const BottomP = styled.p`
  margin: 0;
`;

const NoResultsDiv = styled.div`
  background: ${theme.colors.white};
  padding: 16px;
  border-radius: 8px;

  & > h5 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  & > p {
    font-size: 15px;
    margin: 0;
  }
`;

const AlarmsPage = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures } = useCompanyFeatures();

  const { isInitialLoading } = useAgentsContext();
  const {
    alarms,
    ongoingAlarms,
    historicalAlarms,
    isLoading: isAlarmsLoading,
    refetchAlarms,
  } = useAlarmsContext();

  const isLoading = isInitialLoading || isAlarmsLoading;

  const ALARM_LEVELS: Partial<Record<ALARM_LEVEL, { label: string; color: string }>> = useMemo(
    () => ({
      [ALARM_LEVEL.Critical]: {
        label: i18n.t<string>('general.alarm.level.critical'),
        color: getAlarmColorFromLevel(ALARM_LEVEL.Critical).text,
      },
      [ALARM_LEVEL.Warning]: {
        label: i18n.t<string>('general.alarm.level.warning'),
        color: getAlarmColorFromLevel(ALARM_LEVEL.Warning).text,
      },
      // TODO: Only super admins can see Information alarms
      ...(isSuperAdmin
        ? {
            [ALARM_LEVEL.Information]: {
              label: i18n.t<string>('general.alarm.level.information'),
              color: getAlarmColorFromLevel(ALARM_LEVEL.Information).text,
            },
          }
        : {}),
    }),
    [isSuperAdmin],
  );

  const DEFAULT_ALARM_LEVEL_FILTERS: ALARM_LEVEL[] = useMemo(
    () => Object.keys(ALARM_LEVELS) as ALARM_LEVEL[],
    [ALARM_LEVELS],
  );

  const ALARM_GROUP_TYPES: Record<
    string,
    {
      icon: ReactNode;
      types: ALARM_TYPE[];
    }
  > = useMemo(
    () => ({
      general: {
        icon: <ExclamationCircleOutlined />,
        types: [ALARM_TYPE.emergency, ALARM_TYPE.fall],
      },
      abnormalActivity: {
        icon: <TbUserQuestion />,
        types: [ALARM_TYPE.abnormal_stops, ALARM_TYPE.long_stops, ALARM_TYPE.stress],
      },
      gasLevels: {
        icon: <DotChartOutlined />,
        types: [ALARM_TYPE.gas_danger, ALARM_TYPE.gas_high],
      },
      impactDetection: {
        icon: <FullscreenExitOutlined />,
        types: [ALARM_TYPE.traak_front, ALARM_TYPE.traak_back],
      },
    }),
    [],
  );

  const visibleAlarmTypesSet = useMemo(() => new Set(alarms.map((alarm) => alarm.type)), [alarms]);

  const visibleAlarmGroupTypes: typeof ALARM_GROUP_TYPES = useMemo(
    () =>
      Object.entries(ALARM_GROUP_TYPES).reduce(
        (acc, [groupName, groupData]) => {
          const filteredTypes = groupData.types.filter((type) => visibleAlarmTypesSet.has(type));

          if (filteredTypes.length > 0) {
            acc[groupName] = {
              ...groupData,
              types: filteredTypes,
            };
          }

          return acc;
        },
        {} as typeof ALARM_GROUP_TYPES,
      ),
    [ALARM_GROUP_TYPES, visibleAlarmTypesSet],
  );

  const DEFAULT_ALARM_TYPE_FILTERS: ALARM_TYPE[] = Object.values(ALARM_GROUP_TYPES)
    .map(({ types }) => types)
    .flat();

  const [agentIdFilter, setAgentIdFilter] = useState<string | undefined>();
  const [alarmTypeFilters, setAlarmTypeFilters] = useState<ALARM_TYPE[]>(
    DEFAULT_ALARM_TYPE_FILTERS,
  );
  const [alarmLevelFilters, setAlarmLevelFilters] = useState<ALARM_LEVEL[]>(
    DEFAULT_ALARM_LEVEL_FILTERS,
  );

  const alarmsByLevel = useMemo(() => groupBy(alarms, (alarm) => alarm.level), [alarms]);

  const alarmsByType = useMemo(() => groupBy(alarms, (alarm) => alarm.type), [alarms]);

  const agentOptions = useMemo(() => {
    const groupedByAgent = groupBy(
      orderBy(alarms, ['carrier.name'], ['asc']),
      (alarm) => alarm?.carrier?.completeName,
    );
    return orderBy(
      Object.entries(groupedByAgent).map(([completeName, agentAlarms]) => ({
        value: agentAlarms?.[0]?.carrier?.id,
        label: completeName,
        labelNormalized: completeName.toLocaleLowerCase(),
      })),
      ['labelNormalized'],
      ['asc'],
    );
  }, [alarms]);

  const filterAlarms = useCallback(
    (alarmList: AlarmWithCarrier[]) =>
      alarmList.filter(
        (alarm) =>
          alarmLevelFilters.includes(alarm?.level) &&
          alarmTypeFilters.includes(alarm?.type) &&
          (!agentIdFilter || alarm?.carrier?.id === agentIdFilter),
      ),
    [agentIdFilter, alarmLevelFilters, alarmTypeFilters],
  );

  const filteredAlarms = useMemo(() => filterAlarms(alarms), [filterAlarms, alarms]);

  const filteredOngoingAlarms = useMemo(
    () => filterAlarms(ongoingAlarms),
    [filterAlarms, ongoingAlarms],
  );

  const filteredHistoricalAlarmsByDay = useMemo(
    () => groupBy(filterAlarms(historicalAlarms), (alarm) => formatDate(alarm.created_at)),
    [filterAlarms, historicalAlarms],
  );

  const hasAlarms = alarms.length > 0;

  useEffect(() => {
    refetchAlarms?.();
  }, [refetchAlarms]);

  return (
    <GridDiv>
      <PageContentWrapper>
        <Container>
          <div>
            <PageHeader title={i18n.t('common.alarms')} subtitle={i18n.t('alarmsPage.subtitle')} />
            <AlarmLevelFiltersUl>
              {Object.entries(ALARM_LEVELS).map(([key, { label, color }]) => {
                const level = key as ALARM_LEVEL;
                const checked = alarmLevelFilters.includes(level);

                return (
                  <li key={label}>
                    <AlarmLevelFilterButton
                      disabled={isLoading}
                      onClick={() => {
                        setAlarmLevelFilters((prevAlarmLevelFilters) => {
                          if (prevAlarmLevelFilters.includes(level)) {
                            return prevAlarmLevelFilters.filter((t) => t !== key);
                          }
                          return [...prevAlarmLevelFilters, level];
                        });
                      }}
                    >
                      {!isLoading && (
                        <span
                          style={{
                            transition: 'all 0.1s ease',
                            backgroundColor: checked ? color : theme.colors.grey,
                            color: checked ? theme.colors.white : theme.colors.black,
                          }}
                        >
                          {alarmsByLevel[level]?.length || 0}
                        </span>
                      )}
                      <div>{label}</div>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: checked ? color : theme.colors.grey,
                          },
                        }}
                      >
                        <Checkbox checked={checked} />
                      </ConfigProvider>
                    </AlarmLevelFilterButton>
                  </li>
                );
              })}
            </AlarmLevelFiltersUl>
            {filteredOngoingAlarms.map((alarm) => (
              <div key={alarm.id} style={{ marginBottom: '16px' }}>
                <AlarmNotificationCard alarm={alarm} initialOpen />
              </div>
            ))}
            {isLoading ? (
              <Skeleton active />
            ) : (
              <>
                {Object.entries(filteredHistoricalAlarmsByDay).map(([day, dayAlarms], index) => (
                  <AlarmNotificationGroup
                    key={day}
                    title={new Date(day).toLocaleString(currentLanguage(), {
                      month: 'long',
                      day: 'numeric',
                    })}
                    alarms={dayAlarms}
                    initialOpen={index === 0}
                  />
                ))}
                {filteredAlarms.length === 0 && (
                  <NoResultsDiv>
                    <h5>
                      {hasAlarms
                        ? i18n.t('alarmsPage.noFilteredResultsTitle')
                        : i18n.t('alarmsPage.noRawResultsTitle')}
                    </h5>
                    <p>
                      {hasAlarms
                        ? i18n.t('alarmsPage.noFilteredResultsDescription')
                        : i18n.t('alarmsPage.noRawResultsDescription')}
                    </p>
                    {hasAlarms && (
                      <Button
                        style={{ marginTop: '16px' }}
                        icon={<CloseCircleOutlined />}
                        type="primary"
                        onClick={() => {
                          setAgentIdFilter(undefined);
                          setAlarmLevelFilters(DEFAULT_ALARM_LEVEL_FILTERS);
                          setAlarmTypeFilters(DEFAULT_ALARM_TYPE_FILTERS);
                        }}
                      >
                        {i18n.t('alarmsPage.resetFilters')}
                      </Button>
                    )}
                  </NoResultsDiv>
                )}
              </>
            )}
          </div>
        </Container>
      </PageContentWrapper>
      <Aside>
        <StickySideFiltersDiv>
          <FilterH4>
            <TeamOutlined />
            <span>{i18n.t('alarmsPage.groups.agents')}</span>
          </FilterH4>
          <FiltersUl>
            <AgentSelect
              allowClear
              placeholder={i18n.t('alarmsPage.filterAlarmsByAgent')}
              optionFilterProp="children"
              onChange={(value) => {
                setAgentIdFilter(value);
              }}
              value={agentIdFilter}
              disabled={agentOptions.length === 0}
              loading={isLoading}
              options={[
                {
                  value: '',
                  label: i18n.t('alarmsPage.allAgents'),
                },
                ...agentOptions,
              ]}
            />
          </FiltersUl>
          {Object.entries(visibleAlarmGroupTypes)
            .filter(([groupName]) => {
              if (
                groupName === 'impactDetection' &&
                !companyFeatures?.impactDetectionFront &&
                !companyFeatures?.impactDetectionBack
              ) {
                return false;
              }
              if (groupName === 'gasLevels' && !companyFeatures?.gasSensor) {
                return false;
              }
              return true;
            })
            .map(([group, { icon, types }]) => (
              <div key={group}>
                <FilterH4>
                  {icon}
                  <span>{i18n.t(`alarmsPage.groups.${group}`)}</span>
                </FilterH4>
                <FiltersUl>
                  {types
                    .filter((type) => {
                      if (
                        type === ALARM_TYPE.traak_front &&
                        !companyFeatures?.impactDetectionFront
                      ) {
                        return false;
                      }
                      if (type === ALARM_TYPE.traak_back && !companyFeatures?.impactDetectionBack) {
                        return false;
                      }
                      return true;
                    })
                    .map((type) => {
                      const title = i18n.t(`general.alarm.type.${type}`);
                      const alarmsCount = alarmsByType[type]?.filter((a) =>
                        agentIdFilter ? a.carrier.id === agentIdFilter : true,
                      )?.length;
                      const count = isLoading ? 0 : alarmsCount || 0;
                      const label = `${title} (${count})`;
                      return (
                        <li key={type}>
                          <Switch
                            checked={alarmTypeFilters.includes(type)}
                            loading={isLoading}
                            onChange={() => {
                              setAlarmTypeFilters((prevAlarmTypeFilters) => {
                                if (prevAlarmTypeFilters.includes(type)) {
                                  return prevAlarmTypeFilters.filter((t) => t !== type);
                                }
                                return [...prevAlarmTypeFilters, type];
                              });
                            }}
                          />
                          <div
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              gap: '0.35rem',
                            }}
                          >
                            {label}
                            <Tooltip
                              title={i18n.t(`general.alarm.description.${type}`)}
                              placement="top"
                            >
                              <div
                                style={{
                                  cursor: 'help',
                                  transform: 'translateY(1px)',
                                  opacity: 0.5,
                                }}
                              >
                                <InfoCircleOutlined />
                              </div>
                            </Tooltip>
                          </div>
                        </li>
                      );
                    })}
                </FiltersUl>
              </div>
            ))}
          <BottomP>
            <EyeOutlined />{' '}
            {isLoading
              ? i18n.t('alarmsPage.loadingAlarms')
              : i18n.t('alarmsPage.showingXAlarms', {
                  alarmsRatio:
                    filteredAlarms.length === alarms.length
                      ? filteredAlarms.length
                      : `${filteredAlarms.length}/${alarms.length}`,
                })}
          </BottomP>
        </StickySideFiltersDiv>
      </Aside>
    </GridDiv>
  );
});

export default AlarmsPage;
